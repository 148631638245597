@import './node_modules/@sbt-suite/components/theme/variables.scss';
@import './node_modules/@sbt-suite/components/theme/globalClasses.scss';

:root {
    --primary-color: #006eff;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    * {
        box-sizing: border-box;
    }
}
.key-value {
    label {
        font-weight: 500;
    }
    span {
        margin-left: 8px;
    }
}

.fw-500 {
    font-weight: 500;
}

.no-checkbox {
    mat-checkbox {
        display: none;
    }
}
